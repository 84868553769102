import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AshGuidesBeg: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Beginner Guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_beginner.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Ash Echoes Beginner Guide</h1>
          <h2>
            A beginner guide for Ash Echoes that will teach you everything you
            need to know about the game!
          </h2>
          <p>
            Last updated: <strong>14/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Foreword" />
              <p>
                So you decided to play Ash Echoes. Welcome to Senlo! The first
                advice we can give here is,{' '}
                <strong>
                  do not approach this game expecting a standard gacha
                </strong>
                . Aurogon Shanghai, the developers of Ash Echoes, are well
                established in developing single-player games and even an MMO,
                with this being their first gacha. It was their choice to go for
                an unorthodox approach that may make some people feel confused
                or underwhelmed at first.
              </p>
              <p>
                But don’t worry! Many of the standards of gachas still apply
                here, and for the ones that don’t, we’ll try our best to make
                your experience as smooth and enjoyable as possible.
              </p>
              <p>
                If you still have no idea what kind of game this is, we highly
                recommend checking out our “Introduction to the game” page
                first:
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Introduction to the game"
                  link="/ash-echoes/guides/introduction-to-the-game"
                  image={
                    <StaticImage
                      src="../../../images/ash/categories/category_intro.webp"
                      alt="Introduction to the game"
                    />
                  }
                />
              </Row>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="First Steps" />
              <p>
                The Prologue of the game is quite simple and straightforward. It
                will introduce you to the basics of the RTS aspect of the
                gameplay, including how to deal with block gauge, intercept
                projectiles and basic elemental reactions.
              </p>
              <StaticImage
                src="../../../images/ash/generic/beg_1.webp"
                alt="Guides"
              />
              <p>
                At first, you will have the Director (aka the “Main Character”,
                also known as “you”) as the Team Leader. The Director doesn’t
                really participate in battles and stays in the background,
                offering bonus stats. After you’re done with the prologue, you
                can claim your Pre-registration and initial rewards and start
                summoning. If you wish some guidance on which characters to
                start with or who to reroll for, we got you covered:
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Reroll Guide"
                  link="/ash-echoes/guides/reroll"
                  image={
                    <StaticImage
                      src="../../../images/ash/categories/category_reroll.webp"
                      alt="Reroll Guide"
                    />
                  }
                />
              </Row>
              <p>
                Relax, keep on playing and enjoying the story if you’re into
                that, or skipping and enjoying the gameplay.
              </p>
              <p>
                Once you finish the first chapter, you will be introduced to one
                of the main game modes of Ash Echoes:{' '}
                <strong>“Echoing Nexus”</strong>.
              </p>
              <p>Here comes the big ~spooky-scary-overwhelming-complex part~</p>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="The Game Loop" />
              <p>The game loop of Ash Echoes can be summarized into:</p>
              <ol>
                <li>
                  <strong>Collect Memory Traces</strong> - They come from gacha,
                  story rewards, event rewards, selectors, battle pass. More
                  details later;
                </li>
                <li>
                  Open the Echoing Nexus interface, select the path you want to
                  run and your Leader character;
                </li>
                <li>Equip your Traces in your Leader character;</li>
                <li>Run Echoing Nexus.</li>
                <li>
                  Once done, you will have an amount of skill points to allocate
                  (most of the time you can just click “Recommended”) and that
                  will give you an “Engraving”.
                </li>
              </ol>
              <p>
                In short,{' '}
                <strong>
                  Memory Traces are nothing more, nothing less than the
                  equivalent of pieces of “gear” here.
                </strong>{' '}
                You combine them into a set that will represent one version of
                your character, called <strong>“Engraving”</strong>, and this is
                what you will use in any other content in the game. All the
                stats and skills contained in the Engraving will affect the
                entire team, so you only need to worry about building your
                Leader character. However, do so keeping in mind the kind of
                team you want to use them with.
              </p>
              <p>
                <strong>
                  TL;DR: Level your character Ascension/Skills and your Memory
                  Traces, Run the Roguelike mode, use the final Engraving.
                </strong>
              </p>
              <StaticImage
                src="../../../images/ash/generic/beg_2.webp"
                alt="Guides"
              />
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Common Questions" />
              <h6>Can I have more than one Engraving per character?</h6>
              <p>
                Yes. You can have as many as you want. Deleting old engravings
                gives you Memory Trace upgrade materials, so you don’t need to
                worry about “wasting” anything or bricking your account.
              </p>
              <h6>I have no idea which Memory Traces to use!</h6>
              <p>
                For most casual content, you can just click “Recommended” and
                not think about it. You can also just copy and paste
                recommendations available here on Prydwen or from other CCs. If
                you feel daunting enough to read (*gasp*) you can have fun
                making your own builds and theory crafting. As said above, it’s
                never a waste, don’t be afraid to experiment!
              </p>
              <h6>How do I know what to choose when running the Nexus?</h6>
              <p>
                The Nexus is quite intuitive and most of the time one of the
                options will have a far higher number than the others. Just pick
                that.
              </p>
              <h6>
                Do my choices in the Nexus affect my Traces outside of it?
              </h6>
              <p>No.</p>
              <h6>The Echoing Nexus takes too long to finish!</h6>
              <p>
                On your first run, yes. But you can skip stages you already
                cleared, there are both a Full Auto and Semi Auto modes, and the
                more you run it, the easier it gets. Running Nexus with
                different characters increases its “reputation”, giving you more
                stats bonuses and thus making the runs faster and smoother.
              </p>
              <h6>
                With Traces being such a fundamental part of the game and having
                a banner for them, I feel it’s not very F2P friendly…
              </h6>
              <p>
                A lot of the staple Traces are free. You will see “Behind the
                Curtains”, “Karma”, “Beneath the Deception”, “Codename
                Diversity” a lot in build recommendations, either as BiS (Best
                in Slot) or as a good alternative. Each Main Story chapter gives
                you an SSR Trace (that you can max dupe for free with friendship
                currency in the shop). Every 300 pulls you can select an SSR
                Trace, you get a free SR and a free SSR Trace selector from
                Beginner missions.
              </p>
              <p>
                A good amount of SR Traces have very good skills and can be
                better in some builds than SSR ones. The game also regularly
                gives you “Memory Backflash”, the currency to pull on the Memory
                Traces banner, so you can just use that to focus on pulling for
                Traces that are more vital to your teams. Oh, and there’s a
                Wishlist option too! But you likely won’t touch that unless
                you’re a spender.
              </p>
              <p>More details on Echoing Nexus here:</p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Echoing Nexus Overview"
                  link="/ash-echoes/guides/echoing-nexus"
                  image={
                    <StaticImage
                      src="../../../images/ash/categories/category_nexus.webp"
                      alt="Echoing Nexus Overview"
                    />
                  }
                />
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Freebies and Tips for them" />
              <p>
                As you may have noticed by now, Ash Echoes gives a lot of
                freebies. From early game alone, you can get these Echomancers
                for free:
              </p>
              <ul className="bigger-margin">
                <li>
                  <AshCharacter
                    slug="su-xiao"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </li>
                <li>
                  <AshCharacter
                    slug="di-yan"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </li>
                <li>
                  <AshCharacter
                    slug="tian-ruo"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                </li>
                <li>
                  <AshCharacter
                    slug="yuqi"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  (x2)
                </li>
                <li>
                  <AshCharacter
                    slug="pris"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                </li>
                <li>
                  <AshCharacter
                    slug="changyao"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                </li>
                <li>
                  <AshCharacter
                    slug="xiangling"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                </li>
                <li>
                  <AshCharacter
                    slug="reid"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </li>
                <li>
                  <AshCharacter
                    slug="ms-lew"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                </li>
                <li>
                  <AshCharacter
                    slug="roar"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                </li>
                <li>
                  <AshCharacter
                    slug="rodney"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                </li>
                <li>
                  <AshCharacter
                    slug="bellia"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                </li>
                <li>
                  <AshCharacter
                    slug="sambheka"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  (Discord giveaway at launch).
                </li>
                <li>one 5* selector from beginner missions,</li>
                <li>one random 6* from beginner missions.</li>
              </ul>
              <p>
                In fact, one of the first screens you will stumble upon is this:
              </p>
              <StaticImage
                src="../../../images/ash/generic/beg_3.webp"
                alt="Guides"
              />
              <p>
                You can pick whatever option fancies you more. This screen will
                pop up at account levels 10, 20 and 30, so you will end up
                getting all of them sooner or later. If you really want someone
                telling you what to do, the “Explosion” set is the most
                “”powerful”” for early game and can carry you for a good while,
                especially if you get Cyros.
              </p>
              <p>
                <strong>
                  After your first 30 Summons, you will be given a 6-star
                  selector
                </strong>
                . You will get another at 300 summons, and a free random 6-star
                after finishing Phase 6 of the beginner missions (takes roughly
                a couple of days and finishing Chapter 3).{' '}
                <strong>
                  It’s advised you pull until you get at least your first 6-star
                  before using the Selector.
                </strong>{' '}
                If you can wait until you get your random 6-star, even better.
                If you need help deciding what 6-star to pick, our Reroll Guide
                has advice on that, you can choose based on the kind of team you
                want to build.
              </p>
              <p>
                For 5-stars, it’s highly recommended to get{' '}
                <AshCharacter
                  slug="freda"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                to 3 Dupes as soon as possible, as her buffs are quite powerful
                and fit every team.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Character Progression" />
              <p>
                Characters don’t have “levels” in Ash Echoes. Instead, you
                increase each character individual power through the following
                ways:
              </p>
              <h6>Ascension:</h6>
              <p>
                Ranging from S0 to S6, Ascension will dictate your character’s
                base stats, level of their Leader Skills, and max level
                available for their Combat Skills.
              </p>
              <h6>Combat Skills:</h6>
              <p>
                Pretty straightforward, you upgrade the skills that your
                character uses in combat.
              </p>
              <StaticImage
                src="../../../images/ash/generic/beg_4.webp"
                alt="Guides"
              />
              <h6>Seed:</h6>
              <p>
                “Seed Skill” is how Ultimates are referred to here. Character
                dupes are the only way to increase a Seed Skill level. At Dupe 1
                and 3, the character also gets an improvement to their combat
                traits.
              </p>
              <h6>Fitness Training:</h6>
              <p>
                Available at S.E.E.D. dome, it’s a way to further increase base
                stats. For this reason, it’s recommended to focus on upgrading
                the “Fitness Room” of the Dome first.
              </p>
              <StaticImage
                src="../../../images/ash/generic/beg_5.webp"
                alt="Guides"
              />
              <h6>Potential Exploration:</h6>
              <p>
                This part only matters if you’re using the character as the
                Leader of the team. Completing Milestones in the “Leader Record”
                will award you Crystaplates, while running the “Path to
                Proficiency” game mode will award you with Badges of Origins. By
                upgrading a character’s Potential Exploration, you make them
                fairly stronger, as well as enabling several passives both for
                themselves and the rest of the team.
              </p>
              <StaticImage
                src="../../../images/ash/generic/beg_6.webp"
                alt="Guides"
              />
              <SectionHeader title="What now?" />
              <p>
                Ash Echoes has many game modes that range from casual to
                challenging. You can check our dedicated section that lists them
                here:
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Game Modes"
                  link="/ash-echoes/guides/game-modes"
                  image={
                    <StaticImage
                      src="../../../images/ash/categories/category_modes.webp"
                      alt="Game Modes"
                    />
                  }
                />
              </Row>
              <p>
                <strong>Dailies only take a couple of minutes</strong>, and you
                don’t need to worry too much about “stamina overflow” since it
                will get stored permanently (up to 2400) and you can draw from
                it any time. Dailies and weeklies also give a decent amount of
                refreshers (Food). Most game modes don’t use Activity, so you’ll
                be using the chunk of them to progress through the main story
                and in farm stages (sweep function exists).
              </p>
              <StaticImage
                src="../../../images/ash/generic/beg_7.webp"
                alt="Guides"
              />
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Tips for Early Optimization" />
              <h6 className="colored">
                Tip 1: Your Director has an Engraving too!
              </h6>
              <p>
                Unlike the free flow format of the Echomancer builds, the
                Director equips one Trace of each stat. The more dupes that
                Trace has, the higher the stat conversion. For this reason,
                unless you’re a whale, often the best Traces to equip on the
                Director will be the free SSR with max dupes you get from
                events. The Director’s Engraving only has the purpose of giving
                your Leader extra stats. You don’t need to think too much about
                it.
              </p>
              <StaticImage
                src="../../../images/ash/generic/beg_8.webp"
                alt="Guides"
              />
              <h6 className="colored">
                Tip 2: Engraving Rating gives a ton of gacha currency and
                account exp.
              </h6>
              <p>
                Running Echoing Nexus with varied characters can help level this
                up fast. But for early game, there’s an easy tip that you can
                follow. You can click the “Simulation” button to generate an
                Engraving in a couple of clicks, without having to run Nexus!
                This Engraving will borrow from what you have equipped in your
                Director, and will be very inferior compared to the ones from an
                actual Nexus run, but hey, we’re trying to speed up account
                progression and get more summons here. As your level grows, the
                “Simulation” method becomes less and less effective.
              </p>
              <StaticImage
                src="../../../images/ash/generic/beg_9.webp"
                alt="Guides"
              />
              <h6 className="colored">Tip 3: Join a Guild (aka Club).</h6>
              <p>
                Daily Guild activity takes less than a minute, and you can get a
                lot of free materials from the Guild Shop. The Club Event “Echo
                Hunt” (a cooperative boss challenge) also gives a lot of free
                materials, mainly to upgrade skills.
              </p>
              <StaticImage
                src="../../../images/ash/generic/beg_10.webp"
                alt="Guides"
              />
              <h6 className="colored">Tip 4: Unlocking Boss</h6>
              <div className="employees-container-card-mode-ash">
                <Card className="avatar-card">
                  <AshCharacter
                    slug="boss"
                    mode="card"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <p>
                Boss is one of the 1-star characters unlockable through
                achievements only. She requires 4000 Coziness in the Lounge (Tea
                Time). Boss is a key character for Speedruns on Leaderboards. If
                you are into that (or if you just like cats), unlocking her can
                be quite fast and hassle-free:
              </p>
              <ol>
                <li>Join a Club</li>
                <li>Buy “Tea Time Universal Invitation”</li>
                <li>Spam Tea Time</li>
                <li>Buy Furniture until you reach 4000 coziness</li>
              </ol>
              <h6 className="colored">Tip 5: Light Spender Tips</h6>
              <p>
                You can spend <strong>$0,99</strong> to get{' '}
                <AshCharacter
                  slug="freda"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                and at the same time, by spending any amount in the game, you
                also get{' '}
                <AshCharacter
                  slug="begonia"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                Both are fantastic 5-star characters.
              </p>
              <p>
                In terms of spending priority, as is par for the course, Monthly
                Pass (S.E.E.D. Pass) is the best cost-benefit{' '}
                <strong>$4,99</strong> for roughly 20 summons and lots of extra
                Activity, followed by the Battle Pass (Project Vein){' '}
                <strong>$9,99</strong> for a max dupe SR Trace, several
                materials and summons.
              </p>
              <StaticImage
                src="../../../images/ash/generic/beg_11.webp"
                alt="Guides"
              />
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Videos" />
              <Row xs={1} xl={3} className="video-row">
                <Col>
                  <YoutubeEmbed embedId="3AEE2QLFqRU" />
                </Col>
              </Row>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6',
                'section-7'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Foreword</a>
                </li>
                <li>
                  <a href="#section-1">First Steps</a>
                </li>
                <li>
                  <a href="#section-2">The Game Loop</a>
                </li>
                <li>
                  <a href="#section-3">Common Questions</a>
                </li>
                <li>
                  <a href="#section-4">Freebies and Tips</a>
                </li>
                <li>
                  <a href="#section-5">Character Progression</a>
                </li>
                <li>
                  <a href="#section-6">Tips for Early Optimization</a>
                </li>
                <li>
                  <a href="#section-7">Videos</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesBeg;

export const Head: React.FC = () => (
  <Seo
    title="Beginner Guide | Ash Echoes | Prydwen Institute"
    description="A beginner guide for Ash Echoes that will teach you everything you need to know about the game!"
    game="ash"
  />
);
